import { useState } from "react";
import FAQ from "./utils/FAQ";
import { Sash } from "./utils/Sash";
import { useNavigate } from "react-router-dom";

export default function Page() {
    const [bannerloaded, setBannerLoaded] = useState(false);
    const [FAQloaded, setTimelineLoaded] = useState(false);

    const navigate = useNavigate();

    const [backgroundVideoloaded, setBackgroundVideoLoaded] = useState(false);

    const handleContactClick = async () => {
        // Navigate to "events/upcoming" first
        await navigate(`/contact`);
        window.scroll(0, 0);

        // Wait for a short duration before navigating to the specific event section
        setTimeout(() => {
            const eventSection = document.getElementById("contact-form");
            if (eventSection) {
                eventSection.scrollIntoView({ behavior: "smooth" });
            }
        }, 500);
    };

    return (
        <div
            style={{
                transition: "opacity 0.5s ease-in-out",
                opacity: bannerloaded ? 1 : 0,
            }}
        >
            <div className="relative">
                <div className="absolute inset-0 flex flex-col justify-center pointer-events-none pt-[25%] md:pt-[20%] pl-[4%] md:pl-[5%] px-[10%]  lg:pl-[10%]">
                    <div className="text-white  text-left pb-[30%]">
                        <div className="inline-block">
                            <h1 className="text-4xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl font-bold">
                                Join Us
                            </h1>
                        </div>
                    </div>
                </div>
                <img
                    src="/assets/banners/joinus/splits_jump.webp"
                    style={{ width: "100%", height: "auto" }}
                    width={1920}
                    height={1080}
                    loading="eager"
                    alt="Dancers Cover"
                    className="object-cover midmd:w-full h-auto md:h-full"
                    onLoad={() => setBannerLoaded(true)}
                />
            </div>
            <div className="">
                <div className="bg-colour-light pt-[2%]">
                    <div className=" margin-centered pb-[2%]">
                        <p className="font-bold text-black text-md lg:text-lg xl:text-1xl pb-5">
                            Are you passionate about dance and interested in
                            preserving Ukrainian culture? We&apos;d love for you
                            to join us! Check out the poster below to find out
                            about our upcoming auditions and take your first
                            step towards becoming a part of our vibrant
                            ensemble. Can&apos;t wait until the next audition?
                            Reach out us here!
                        </p>
                        <span
                            className={`px-4 py-3 font-semibold shadow-md no-underline rounded-full bg-yevshan-gold hover:cursor-pointer text-black md:text-lg lg:text-xl border-blue btn-primary  hover:bg-[#b8b795] focus:outline-none active:shadow-none mr-2 whitespace-nowrap`}
                            onClick={() => handleContactClick()}
                        >
                            {`Contact us`}
                        </span>
                    </div>
                </div>

                <div className="bg-[#cccccc]">
                    <div className="margin-centered items-center justify-self relative w-full">
                        <div className="realtive md:px-[20%] py-[2%]">
                            <img
                                src="/assets/joinus/auditions_blank.png"
                                width={500}
                                height={500}
                                alt=""
                                style={{ width: "100%", height: "auto" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-colour-light">
                    <div className="margin-centered pb-[5%]">
                        <h2 className="text-3xl md:text-2xl lg:text-3xl xl:text-4xl font-bold pb-[1%] pt-[5%] text-black">
                            FAQ
                        </h2>
                        <FAQ onLoad={() => setTimelineLoaded(true)} />
                    </div>
                </div>
            </div>
            <Sash />
        </div>
    );
}
