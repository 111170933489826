import Timeline from "./utils/Timeline";
import { Sash } from "./utils/Sash";
import { useState } from "react";
import "../App.css";

type TimelineEvents = {
    year: string;
    content: string;
    section: number;
};

export default function Page() {
    const [bannerloaded, setBannerLoaded] = useState(false);
    const [timelineloaded, setTimelineLoaded] = useState(false);

    return (
        <>
            <div
                className={`relative `}
                style={{
                    transition: "opacity 0.5s ease-in-out",
                    opacity: bannerloaded ? 1 : 0,
                }}
            >
                <div className="absolute inset-0 flex flex-col justify-center pointer-events-none pt-[25%] md:pt-[20%] pl-[4%] md:pl-[5%] px-[10%] lg:pl-[10%]">
                    <div className="text-white  text-left pb-[30%]">
                        <div className="inline-block">
                            <h1 className="text-4xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl font-bold">
                                History
                            </h1>
                        </div>
                    </div>
                </div>
                <img
                    src="/assets/banners/history/flag.webp"
                    width={1920}
                    height={1080}
                    alt="Dancers Cover"
                    loading="eager"
                    className={`object-cover midmd:w-full h-auto md:h-full ${
                        bannerloaded ? "loaded" : "loading"
                    }`}
                    onLoad={() => setBannerLoaded(true)}
                />
            </div>
            <div
                className={`bg-colour-dark text-black `}
                style={{
                    transition: "opacity 0.5s ease-in-out",
                    opacity: timelineloaded ? 1 : 0,
                }}
            >
                <div className="container mx-auto px-4">
                    <h1 className="text-2xl font-bold text-center pt-4 mb-4">
                        Timeline of Yevshan
                    </h1>
                    <Timeline onLoad={() => setTimelineLoaded(true)} />
                </div>
                <Sash />
            </div>
        </>
    );
}
