"use client";

import React from "react";
import "../../App.css";
import yevshan_logo from "../../yevshan_logo_transparent.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import Dropdown from "./Dropdown-Selection";
import { MenuItem, menuItems } from "../../interfaces/MenuItems";
import { GoldButton } from "./GoldButton";
import { useMediaQuery } from "../../hooks/useScreenSize";

export const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [logoloaded, setLogoLoaded] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const menuClasses = [];

    const isBreakPoint = useMediaQuery(820);

    return (
        <nav className="bg-gray-100 text-gray-950 md:flex md:justify-between md:items-center ">
            <div
                className="container mx-auto flex justify-between items-center"
                style={{
                    transition: "opacity 0.5s ease-in-out",
                    opacity: logoloaded ? 1 : 0,
                }}
            >
                <Link
                    to="/"
                    className="text-2xl font-bold pl-4 sm:pl-0 lg:pl-10"
                    onClick={() => {
                        if (isOpen) {
                            setIsOpen(!isOpen);
                        }
                    }}
                >
                    <div className="relative w-24 h-24 sm:w-32 sm:h-32 md:w-30 md:h-30 lg:w-30 lg:h-30">
                        <img
                            src={yevshan_logo}
                            alt="Yevshan"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                            }}
                            onLoad={() => setLogoLoaded(true)}
                        />
                    </div>
                </Link>

                <div
                    className={`${
                        isOpen
                            ? "flex absolute z-20 top-[90px] sm:top-[125px] bg-colour-light w-full p-4 left-0 gap-10 flex-col"
                            : "hidden"
                    }  midmd:flex-row midmd:flex flex-col items-baseline midmd:items-center midmd:space-x-3 lg:space-x-10`}
                >
                    {menuItems.map((item) => {
                        return item.hasOwnProperty("children") ? (
                            <Dropdown
                                key={item.route}
                                item={item}
                                toggleNavbar={toggle}
                            />
                        ) : (
                            <Link
                                key={item?.route || ""}
                                to={item?.route || ""}
                                className="mx-2 hover:text-yevshan-gold font-semibold md:text-xl lg:text-xl"
                                onClick={() => {
                                    if (isBreakPoint) {
                                        setIsOpen(!isOpen);
                                    }
                                }}
                            >
                                {item.title}
                            </Link>
                        );
                    })}

                    <GoldButton href="/mailing" text="Mailing List" onClick={() => {
                        if (isBreakPoint) {
                            setIsOpen(!isOpen);
                        }
                    }}/>
                </div>

                <div className="midmd:hidden flex flex-col justify-center items-center pr-4">
                    <button
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        <span
                            className={`bg-yevshan-gold block transition-all duration-300 ease-out 
                    h-0.5 w-6 rounded-sm ${
                        isOpen ? "rotate-45 translate-y-1" : "-translate-y-0.5"
                    }`}
                        ></span>
                        <span
                            className={`bg-yevshan-gold block transition-all duration-300 ease-out 
                    h-0.5 w-6 rounded-sm my-0.5 ${
                        isOpen ? "opacity-0" : "opacity-100"
                    }`}
                        ></span>
                        <span
                            className={`bg-yevshan-gold block transition-all duration-300 ease-out 
                    h-0.5 w-6 rounded-sm ${
                        isOpen ? "-rotate-45 -translate-y-1" : "translate-y-0.5"
                    }`}
                        ></span>
                    </button>
                </div>
            </div>
        </nav>
    );
};
