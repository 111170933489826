import { useEffect, useState } from "react";
import { Question } from "../../interfaces/Question";
import "../../App.css";

interface FAQProps {
    onLoad?: () => void;
}

const FAQ = ({onLoad}: FAQProps) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const [questions, setJSONData] = useState<Question[]>([]);

    useEffect(() => {
        const fetchImageData = async () => {
            try {
                const response = await fetch("/api/getFAQ");
                if (response.ok) {
                    const responseData = await response.json();
                    const data = responseData.data;
                    setJSONData(data);
                    if (onLoad) {
                        onLoad();
                    }
                } else {
                    throw new Error(
                        `Failed to fetch images: ${response.statusText}`
                    );
                }
            } catch (error) {
                console.error("Error fetching images:", error);
                throw error; // Rethrow the error to be caught by the component
            }
        };
        fetchImageData();
    }, []);

    const toggleActive = (index: number) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div>
            {questions.map((item: Question, index: number) => (
                <div key={index} className="mb-4">
                    <div
                        className="flex justify-between items-center cursor-pointer"
                        onClick={() => toggleActive(index)}
                    >
                        <h3 className="text-lg text-black font-medium">
                            {item.question}
                        </h3>
                        <span
                            className={`transform text-black transition-transform ${
                                activeIndex === index ? "rotate-180" : ""
                            }`}
                        >
                            ▼
                        </span>
                    </div>
                    <div
                        className={`overflow-hidden transition-all ${
                            activeIndex === index
                                ? "max-h-auto duration-500"
                                : "max-h-0  duration-0"
                        }`}
                        style={{
                            maxHeight: activeIndex === index ? "1000px" : "0",
                        }}
                    >
                        <p className="mt-2 text-gray-900">{item.answer}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FAQ;
