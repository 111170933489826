import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";

interface SponsorImage {
  image: string;
}

export const AutoPlaySponsors = () => {
  const [images, setImages] = useState<SponsorImage[]>([]);
  const [slidesShown, setSlidesShown] = useState<number>(3); // Default to 3 slides
  const [loading, setLoading] = useState<boolean>(true); // Loading state

  useEffect(() => {
    const fetchImageData = async () => {
      try {
        const response = await fetch("/api/getSponsors");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const { data } = await response.json();
        setImages(data);
      } catch (error) {
        setImages([]);
        console.error("Error fetching Sponsor images:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    const handleResize = () => {
      if (window.innerWidth < 820) {
        setSlidesShown(1); // Show 1 slide on mobile
      } else {
        if (images.length >= 3) {
          setSlidesShown(3);
        } else {
          setSlidesShown(images.length);
        }
      }
    };

    fetchImageData().then(() => handleResize()); // Wait for data fetch to complete before resizing
    window.addEventListener("resize", handleResize);
    handleResize(); // Call initially to set the correct value

    return () => window.removeEventListener("resize", handleResize);
  }, [images]); // Dependency on images to re-run on data change

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: slidesShown,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  if (loading) {
    return <div>Loading...</div>; // Optionally show a loading state
  }

  return (
    <div className="slider-container mx-auto max-w-6xl bg-colour-light h-[100px]">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slidetab flex justify-center items-center p-2">
            <img
              src={`data:image/jpeg;base64,${image.image}`}
              alt={`Sponsor ${index}`}
              className="h-[100px]"
            />
          </div>
        ))}
        {images.map((image, index) => (
          <div key={index} className="slidetab flex justify-center items-center p-2">
            <img
              src={`data:image/jpeg;base64,${image.image}`}
              alt={`Sponsor ${index}`}
              className="h-[100px]"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};
