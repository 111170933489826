export interface MenuItem {
  title: string;
  route?: string;
  children?: MenuItem[];
}

export const menuItems: MenuItem[] = [
  {
      title: "History",
      route: "/history",
  },
  {
      title: "Ensemble",
      route: "/ensemble",
      children: [
          {
              title: "Artistic Director",
              route: "/artistic-director",
          },
          {
              title: "Dancers",
              route: "/dancers",
          },
          {
              title: "Board of Directors",
              route: "/board-of-directors",
          },
      ],
  },
  {
      title: "Events",
      route: "/events",
      children: [
          {
              title: "Upcoming Events",
              route: "/upcoming",
          },
          {
              title: "Past Events",
              route: "/past",
          }
      ],
  },
  {
      title: "Join Us",
      route: "/joinus",
  },
  {
      title: "Contact",
      route: "/contact",
  },
];

