"use client";

import "../../App.css";

import { useEffect, useState } from "react";

import {
    HiOutlineArrowLeft,
    HiOutlineArrowRight,
    HiChevronLeft,
    HiChevronRight,
} from "react-icons/hi";

interface CarouselImage {
    image: string;
}

interface EventCarouselProps {
    images64: string[];
}

export const EventCarousel = ({ images64 }: EventCarouselProps) => {
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

    const nextSlide = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === images64.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevSlide = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? images64.length - 1 : prevIndex - 1
        );
    };

    const goToSlide = (index: number) => {
        setCurrentImageIndex(index);
    };

    return (
        <div className="relative overflow-hidden max-w-full mx-auto">
            <button
                className="absolute top-1/2 left-3 transform -translate-y-1/2 bg-gray-500 opacity-50 hover:opacity-60 rounded-full z-10"
                onClick={prevSlide}
            >
                <HiChevronLeft
                    className="text-gray-300 opacity-50 hover:text-gray-100 hover:opacity-95"
                    size={50}
                />
            </button>
            <button
                className="absolute top-1/2 right-3 transform -translate-y-1/2 bg-gray-500 opacity-50 hover:opacity-60 rounded-full z-10"
                onClick={nextSlide}
            >
                <HiChevronRight
                    className="text-gray-300 opacity-50 hover:text-gray-100 hover:opacity-95"
                    size={50}
                />
            </button>
            <div
                className="flex"
                style={{
                    transform: `translateX(-${currentImageIndex * 100}%)`,
                    transition: "transform 0.5s",
                }}
            >
                {images64.map((image, index) => (
                    <div
                        key={index}
                        className="flex-none w-full flex items-center justify-center"
                    >
                        <img
                            src={`data:image/jpeg;base64,${images64[index]}`}
                            alt="carousel"
                            className="max-w-full max-h-[720px] object-contain"
                        />
                    </div>
                ))}
            </div>
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 bg-gray-500 opacity-0 md:opacity-50 p-1 rounded-full z-20">
                {images64.map((_, index) => (
                    <div
                        key={index}
                        className={`${
                            currentImageIndex === index ? "w-8" : "w-6"
                        } h-2 rounded-full cursor-pointer ${
                            currentImageIndex === index
                                ? "bg-gray-100"
                                : "bg-gray-300 hover:bg-gray-200"
                        }`}
                        onClick={() => goToSlide(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
};
