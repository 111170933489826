import { HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Event } from "../../interfaces/Event";
import { useState, useEffect } from "react";

export const FooterEvents = () => {
    const navigate = useNavigate();
    const [upcoming, setUpcomingData] = useState<Event[]>([]);
    const [past, setPastData] = useState<Event[]>([]);

    useEffect(() => {
        const fetchImageData = async () => {
            try {
                const response = await fetch("/api/getEvents");
                if (response.ok) {
                    const responseData = await response.json();
                    const data: Event[] = responseData.data;
                    const upcomingData = data
                        .filter(
                            (event: Event) => new Date(event.date) >= new Date()
                        )
                        .sort(
                            (a: Event, b: Event) =>
                                new Date(a.date).getTime() -
                                new Date(b.date).getTime()
                        );
                    const pastData = data
                        .filter(
                            (event: Event) => new Date(event.date) < new Date()
                        )
                        .sort(
                            (a: Event, b: Event) =>
                                new Date(b.date).getTime() -
                                new Date(a.date).getTime()
                        );
                    setPastData(pastData);
                    setUpcomingData(upcomingData);
                } else {
                    throw new Error(
                        `Failed to fetch images: ${response.statusText}`
                    );
                }
            } catch (error) {
                console.error("Error fetching images:", error);
                //throw error; // Rethrow the error to be caught by the component
            }
        };
        fetchImageData();
    }, []);

    const handleEventClick = async (event: Event, type: string) => {
        // Navigate to "events/upcoming" first
        await navigate(`/${type}`);
        window.scroll(0, 0);

        // Wait for a short duration before navigating to the specific event section
        setTimeout(() => {
            const eventSectionId = event.name.replaceAll(" ", "");
            const eventSection = document.getElementById(eventSectionId);
            if (eventSection) {
                eventSection.scrollIntoView({ behavior: "smooth" });
            }
        }, 500); // Adjust the duration as needed
    };

    return (
        <div>
            {(upcoming.length > 0 || past.length > 0) && (
                <>
                    {upcoming.slice(0, 3).map((event) => (
                        <p
                            key={event.name}
                            className="my-2 flex items-center hover:text-gray-600 hover:cursor-pointer"
                            onClick={() => handleEventClick(event, "upcoming")}
                        >
                            <HiChevronRight />
                            <span>{event.name}</span>
                        </p>
                    ))}
                    {past.slice(0, 3 - upcoming.length).map((event) => (
                        <p
                            key={event.name}
                            className="my-2 flex items-center hover:text-gray-600 hover:cursor-pointer"
                            onClick={() => handleEventClick(event, "past")}
                        >
                            <HiChevronRight />
                            <span>{event.name}</span>
                        </p>
                    ))}
                </>
            )}
        </div>
    );
};
