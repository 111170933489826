import React, { useState, FormEvent } from "react";
import ReCAPTCHA from "react-google-recaptcha";
//import { FormData } from "../../interfaces/FormData";

interface FormData {
    name: string;
    phone: string;
    email: string;
    info: string;
}

type ContactFormProps = {
    onLoad?: () => void;
};

export default function ContactForm({ onLoad }: ContactFormProps) {
    // State for each input with initial values as empty strings

    const [maploaded, setMapLoaded] = useState(false);

    const contactDisabled = false;

   

    const [formData, setFormData] = useState<FormData>({
        name: "",
        phone: "",
        email: "",
        info: "",
    });
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const recaptchaRef = React.createRef<ReCAPTCHA>();

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCaptchaVerify = (response: string | null): void => {
        if (response) {
            setIsCaptchaVerified(true);
        }
    };

    // Function to handle form submission
    const handleSubmit = async (
        event: FormEvent<HTMLFormElement>
    ): Promise<void> => {
        event.preventDefault();
        /*
        if (!isCaptchaVerified) {
            alert("Please complete the CAPTCHA.");
            return;
        }
        */
        if (contactDisabled) {
            alert(
                "Nice try, our email is yevshan1960@gmail.com contact us here since you're so eager :)"
            );
            return;
        }

        const { name, phone, email, info } = formData;

        if (!name || !email || !info) {
            alert("Please fill in all required fields.");
            return;
        }


        try {
            const response = await fetch("/api/postContact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "email": email,
                    "name": name,
                    "phone": phone,
                    "info": info
                })
            });
            if (response.ok) {
                alert("Form submitted successfully!");
            setFormData({
                name: "",
                phone: "",
                email: "",
                info: "",
            });
            } else {
            alert(
                "An error occurred while submitting the form. Please try again later." 
            );
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert(
                "An error occurred while submitting the form. Please try again later."
            );
        }
    };

    return (
        <div className="bg-colour-light margin-centered text-white p-8">
            <h2 className="text-black text-3xl font-bold mb-4">Message Us</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-black">
                <div>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <input
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Name"
                            className="w-full p-2 bg-colour-dark"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            id="phone"
                            name="phone"
                            type="text"
                            placeholder="Phone"
                            className="w-full p-2 bg-colour-dark"
                            value={formData.phone}
                            onChange={handleInputChange}
                        />
                        <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            className="w-full p-2 bg-colour-dark"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <textarea
                            id="info"
                            name="info"
                            placeholder="Your Message"
                            className="w-full p-2 bg-colour-dark"
                            value={formData.info}
                            onChange={handleInputChange}
                            required
                        ></textarea>

                        <button
                            type="submit"
                            className="w-full bg-yevshan-gold p-2"
                            disabled={contactDisabled}
                        >
                            {contactDisabled ? "Coming Soon" : "SEND"}
                        </button>
                        <div className="flex justify-center">
                            {/*<ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LfxQuwpAAAAAFSUoaG51myPkAU6c1jLOR6X5E7t -> site key"
                                onChange={handleCaptchaVerify}
    />*/}
                        </div>
                    </form>
                </div>
                <div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2449.1524393267932!2d-106.66743009999999!3d52.13154850000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5304f6d9b4ea9089%3A0x8dbb7cc6b045919e!2s205A%20Pacific%20Ave%2C%20Saskatoon%2C%20SK%20S7K%201N9!5e0!3m2!1sen!2sca!4v1714772984090!5m2!1sen!2sca"
                        width="600"
                        height="450"
                        style={{ border: "0" }}
                        loading="eager"
                        referrerPolicy="no-referrer-when-downgrade"
                        onLoad={() => {
                            if (onLoad) onLoad();
                            setMapLoaded(true);
                        }}
                    ></iframe>
                </div>
            </div>
        </div>
    );
}
