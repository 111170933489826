import { Sash } from "./utils/Sash";

export default function Page() {
    return (
        <div className="bg-gray-100 text-black">
            <h1 className="margin-centered text-3xl">Privacy Statement</h1>

            <p className="margin-centered py-10 bg-gray-100">
                We value you and take steps to protect your privacy.
                <br />
                <br /> In general, when you visit our Web sites and access
                information, you remain anonymous. We collect personal
                information from visitors to our sites on a voluntary basis only
                and explain how this information will be used at the time it is
                requested. Personal information may include name, title,
                company, address, phone number, e-mail address, etc.
                <br />
                <br /> We do not sell or rent personal information to third
                parties.
                <br />
                <br /> Our Web servers collect the domain names of visitors to
                our sites. This information is aggregated to determine number of
                visits, average time spent, pages viewed, etc. We use this
                information to measure site usage, as well as to improve the
                content and value of our site.
                <br />
                <br /> Some of our Web pages may contain “cookies”, which send
                and store data on your Web browser. This allows our server to
                recognize you and better serve you when you visit our Web sites
                in the future. Most Web browsers can be configured to not accept
                cookies, or to notify you if a cookie is sent to you.
                <br />
                <br />
                This site contains links to third party sites. We take steps to
                link only to sites that share our high standards and respect for
                privacy. However, we are not responsible for the privacy
                standards and practices of any third parties.
                <br />
                <br /> We strive to keep online visitor information secure
                against unauthorized access and use. This information is
                protected by security measures which are reviewed periodically.
                <br />
                <br /> We may update this policy from time to time. Revisions
                will be posted to this site.
                <br />
                <br />
                Thank you.
            </p>
            <Sash />
        </div>
    );
}
