import { useEffect, useState } from "react";
import ContactForm from "./utils/ContactForm";
import { Sash } from "./utils/Sash";
import { useLocation } from 'react-router-dom';

export default function Page() {
    const [bannerloaded, setBannerLoaded] = useState(false);
    const [formloaded, setFormLoaded] = useState(false);
    const [maploaded, setMapLoaded] = useState(false);

    

    return (
        <>
            <div
                className=" bg-colour-dark"
                style={{
                    transition: "opacity 0.5s ease-in-out",
                    opacity: bannerloaded ? 1 : 0,
                }}
            >
                <div className="relative">
                    <div className=" absolute inset-0 flex flex-col justify-center pointer-events-none pt-[25%] md:pt-[20%] pl-[2%] md:pl-[5%] px-[10%]  lg:pr-[10%]">
                        <div className="text-white text-right pb-[30%]">
                            <h1 className="text-3xl sm:text-4xl midmd:text-5xl lg:text-7xl xl:text-8xl 2xl:text-9xl  font-bold">
                                Contact Us
                            </h1>
                        </div>
                    </div>
                    <img
                        src="/assets/banners/contact/girl_ribbons.webp"
                        style={{ width: "100%", height: "auto" }}
                        width={1920}
                        height={1080}
                        alt="Dancers Cover"
                        loading="eager"
                        className="object-cover midmd:w-full h-auto md:h-full"
                        onLoad={() => setBannerLoaded(true)}
                    />
                </div>
            </div>
            <div
                id="contact-form"
                className="bg-colour-light py-10"
                style={{
                    transition: "opacity 0.5s ease-in-out",
                    opacity: bannerloaded ? 1 : 0,
                }}
            >
                <ContactForm onLoad={() => setMapLoaded(true)}/>
            </div>
            <Sash />
        </>
    );
}
