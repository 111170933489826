import { useState } from "react";
import "../App.css";
import { Sash } from "./utils/Sash";
import Link from "react-router-dom";

export default function Page() {
    const [bannerloaded, setBannerLoaded] = useState(false);

    return (
        <div
            style={{
                transition: "opacity 0.5s ease-in-out",
                opacity: bannerloaded ? 1 : 0,
            }}
        >
            <div className="relative">
                <div className="absolute inset-0 flex flex-col justify-center pointer-events-none pt-[25%] md:pt-[20%] pl-[2%] md:pl-[5%] px-[10%]  lg:pl-[10%]">
                    <div className="text-white text-left pb-[30%]">
                        <h1 className="text-2xl midmd:text-3xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl  font-bold">
                            Vitali Sorokotiaguine
                        </h1>
                        <h2 className="text-1xl midmd:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-bold mt-6">
                            Artistic Director
                        </h2>
                    </div>
                </div>
                <img
                    src="/assets/banners/artistic-director/vitali_open_arms_wide_no_text.webp"
                    width={1920}
                    height={1080}
                    alt="Vitali Sorokotiaguine"
                    loading="eager"
                    className="object-cover midmd:w-full h-auto md:h-full"
                    onLoad={() => setBannerLoaded(true)}
                />
            </div>
            <div className="bg-colour-light">
                <div className="midmd:columns-2 grid-rows-2 relative midmd:max-w-6xl mx-auto pb-[5%] p-3 sm:px-6 lg:px-8 pt-5 md:pt-12 lg:pt-16 xl:pt-20 justify-start">
                    <div className=" text-black text-left">
                        <p>
                            Yevshan is thrilled to employ Mr. Vitali
                            Sorokotiaguine as a full time professional ballet
                            master and the groups artistic director. Vitali has
                            a rich history and a deep connection with the art of
                            dance that spans several decades and crosses
                            international borders. Born and raised in Ukraine,
                            Vitali&apos;s passion for dance was evident from a
                            young age. His formal education in dance began at
                            the Melitopil Cultural Institute, where he honed his
                            skills and deepened his knowledge of traditional and
                            contemporary dance forms.
                            <br />
                            <br />
                            After his studies, Vitali directed the Molodist
                            Dance Ensemble in Zaporizhia, where he developed a
                            reputation for his innovative choreography and
                            leadership. His career further expanded as he took
                            on the role of a dancer and soloist with the Odessa
                            Region Song and Dance Ensemble, showcasing his
                            talent in numerous performances that celebrated
                            Ukrainian culture.
                            <br />
                            <br />
                            In a pivotal move to Canada, Vitali brought with him
                            a wealth of experience and a vision for promoting
                            Ukrainian dance. As the Ballet Master and Regisseur
                            with the Nadzbruchanka Dance Ensemble in Ternopil,
                            he had already established himself as a prominent
                            figure in the dance community. His transition to the
                            artistic leadership of Yevshan marked a new chapter
                            both for him and for the ensemble. Under his
                            guidance, Yevshan has not only preserved but also
                            invigorated Ukrainian dance traditions through
                            performances that resonate with both Ukrainian and
                            international audiences.
                            <br />
                            <br />
                            Vitali&apos;s leadership at Yevshan has been
                            transformative. His dedication to the craft and his
                            commitment to the dancers are evident in the
                            rigorous training and rehearsal schedules he
                            oversees, ensuring that every performance meets his
                            high standards. His efforts have been recognized by
                            Dance Saskatchewan, and he was recently awarded as
                            Dance Instructor of the Year by Dance World. These
                            accolades reflect his impact on the dance community
                            in Saskatchewan and beyond.
                            <br />
                            <br />
                            Yevshan and it&apos;s dancers have a profound
                            respect for Vitali, as his influence extends beyond
                            the stage through his mentorship and guidance for
                            all of his students.
                            <br />
                            <br />
                            When not with Yevshan, Vitali is also dedicated to
                            his own Ukrainian dance school, The Vesnianka
                            Academy of Ukrainian Dance.
                            <br />
                            <br />
                        </p>
                    </div>
                    <div className="relative">
                        <img
                            src="/assets/artistic-director/vitali_toast_cropped.png"
                            // Use layout="responsive" for responsive images
                            width={1920} // Set the width of the image (replace with actual width)
                            height={1080} // Set the height of the image (replace with actual height)
                            alt="Yevshan"
                        />
                    </div>
                </div>
            </div>
            <div className="bg-colour-dark py-[1%] text-black">
                <div className="midmd:grid midmd:grid-cols-2 midmd:max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8 justify-self">
                    <div className="relative w-full flex flex-col items-center justify-start md:order-2">
                        <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold md:pb-[5%] text-center">
                            Lecia Pepenfus
                        </h1>
                        <h2 className="text-1xl md:text-2xl lg:text-3xl xl:text-4xl font-bold pb-[5%] text-center">
                            Assistant Instructor
                        </h2>
                        <img
                            src="/assets/artistic-director/lecia.jpg"
                            width={480}
                            height={270}
                            alt="Lecia Image"
                            className="max-w-[400px] h-auto pb-[5%]"
                        />
                    </div>
                    <div className="flex items-center md:order-1">
                        <p>
                            Lecia has been dancing for more than 40 years. She
                            began studying ballet at the age of 4 years and
                            successfully completed her RAD ballet exams up to
                            Pre-Elementary level and has received instruction in
                            both Cecchetti and Vaganova ballet methods as well.
                            She began Ukrainian dancing with Poltava (in Regina)
                            and then continued to study at the Ukrainian Dance
                            School in Saskatoon. Lecia auditioned for Yevshan at
                            the age of 14 and danced with the ensemble for 13
                            years during which she had the opportunity to travel
                            and perform in Saskatchewan, Canada and
                            internationally. Lecia has been teaching dancing for
                            25 years. She began teaching with Vitali in North
                            Battleford and then continued to teach with him in
                            the early years of Vesnianka. Having lived and
                            taught in both Saskatchewan, Yukon and Vancouver
                            Island for the past 15 years, Lecia has had the
                            opportunity to teach and share her love of Ukrainian
                            dance with students in many different places. Her
                            goal in the studio is to help her students excel at
                            dance but more importantly to teach them life skills
                            that will help them to be excellent citizens of the
                            world. Lecia and her family moved back to Saskatoon
                            in 2019 and she is enjoying teaching with Vesnianka
                            again. She is a trained physiotherapist and is
                            currently building a business as a lifestyle,
                            nutrition and health coach. Lecia lives with her
                            husband and three children on their acreage where
                            they enjoy spending time outdoors and having the
                            occasional dance party in their living room!
                        </p>
                    </div>
                </div>
            </div>

            <Sash />
        </div>
    );
}
