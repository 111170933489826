import { Link } from "react-router-dom";

import {
    HiMail,
    HiPhone,
    HiLocationMarker,
    HiChevronRight,
} from "react-icons/hi";
//import { MenuItem, menuItems } from "../../interfaces/Menuitems";
import { Event } from "../../interfaces/Event";
import { FooterEvents } from "./FooterEvents";
import InstagramButton from "./InstagramButton";
import FacebookButton from "./FacebookButton";
import { AutoPlaySponsors } from "./SponsorSlider";

export const Footer = () => {
    const current_year = new Date().getFullYear();

    return (
        <div id="section_footer">
            <div className="text-black bg-colour-light pt-[1%] pb-[3%]">
                <div
                    id="footer-top"
                    className="grid grid-cols-1 midmd:grid-cols-3 gap-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8"
                >
                    <div className="flex flex-col">
                        <h1 className="text-lg  border-b-2 border-yevshan-gold mb-2 pb-1">
                            Contact Info
                        </h1>
                        <div className="text-black">
                            <a
                                className="flex items-center my-2 hover:text-gray-600"
                                href="https://maps.app.goo.gl/CAzrZMnYD6xuMYP47"
                                target="_blank"
                            >
                                <HiLocationMarker className="mr-2" />
                                205A Pacific Ave, Saskatoon, SK S7K 1N9
                            </a>
                            <div className="flex items-center my-2 ">
                                <HiPhone className="mr-2" />
                                <a
                                    href="tel:3062203874"
                                    className="hover:text-gray-600"
                                >
                                    306-220-3874
                                </a>
                                |
                                <a
                                    href="tel:3063747976"
                                    className="hover:text-gray-600"
                                >
                                    306-374-7976
                                </a>
                            </div>
                            <div className="flex items-center my-2 hover:text-gray-600">
                                <HiMail className="mr-2" />
                                <a href="mailto:yevshan1960@gmail.com">
                                    yevshan1960@gmail.com
                                </a>
                            </div>
                        </div>
                        
                    </div>
                    <div className="flex flex-col">
                        <h1 className="text-lg  border-b-2 border-yevshan-gold mb-2 pb-1">
                            Important Pages
                        </h1>
                        <div>
                            <p className="my-2 flex items-center hover:text-gray-600">
                                <HiChevronRight />
                                <Link
                                    to="/contact"
                                    onClick={() => window.scroll(0, 0)}
                                >
                                    Contact Us
                                </Link>
                            </p>
                            <p className="my-2 flex items-center hover:text-gray-600">
                                <HiChevronRight />
                                <Link
                                    to="/joinus"
                                    onClick={() => {
                                        window.scroll(0, 0);
                                    }}
                                >
                                    Join Us
                                </Link>
                            </p>
                            <p className="my-2 flex items-center hover:text-gray-600">
                                <HiChevronRight />
                                <Link
                                    to="/dancers"
                                    onClick={() => {
                                        window.scroll(0, 0);
                                    }}
                                >
                                    Dancers
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <h1 className="text-lg  border-b-2 border-yevshan-gold mb-2 pb-1">
                            Events
                        </h1>

                        <FooterEvents />
                    </div>
                </div>
               
            </div>
             <div className="py-8 bg-colour-light">
                <h1 className="text-3xl pb-[2%]">Thanks to our sponsors</h1>
                            <AutoPlaySponsors />
            </div>
            <div className="pt-[3%] bg-yevshan-gold">
                <div
                    id="footer-bottom"
                    className="grid grid-cols-1 midmd:grid-cols-3 text-black pb-10 midmd:max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
                >
                    <div className="flex items-center justify-center flex-col relative pb-[3%] midmd:pb-0">
                        <img
                            src="/assets/logos/circle_logo.png"
                            // Use layout="responsive" for responsive images
                            width={75} // Set the width of the image (replaceAll with actual width)
                            height={75} // Set the height of the image (replaceAll with actual height)
                            alt="Yevshan"
                        />
                    </div>
                    <div className="text-center items-center justify-center text-black flex-col flex mb-4 midmd:mb-0">
                        <p className="text-sm">
                            © Copyright {current_year} Yevshan Ukrainian Folk
                            Ballet Ensemble
                        </p>
                        <p className="text-sm">
                            Designed and Created by Icosic Technologies
                        </p>
                        <Link
                            className="text-sm hover:underline"
                            to="/privacy"
                            onClick={() => {
                                window.scroll(0, 0);
                            }}
                        >
                            Privacy Policy
                        </Link>
                    </div>

                    <div className="columns-2  text-white md:text-black relative items-center justify-center flex flex-row midmd:right-10 place-content-end space-x-5 midmd:space-x-10 pt-3">
                        <FacebookButton />
                        <InstagramButton />
                    </div>
                </div>
            </div>
        </div>
    );
};
