import React from "react";
import { Sash } from "./utils/Sash";
import MailForm from "./utils/MailForm";

export default function Page() {
    return (
        <div className="bg-gray-100 text-black">
            <div className="py-[10%]">
                <MailForm />
            </div>

            <Sash />
        </div>
    );
}
