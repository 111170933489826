import { Sash } from "./utils/Sash";
import { Link } from "react-router-dom";

import fs from "fs";
import path from "path";
import { GoldButton } from "./utils/GoldButton";
import { ImageGrid } from "./utils/ImageGrid";
import { ImageGridCell } from "../interfaces/ImageGridCell";
import { useState } from "react";


export default function Page() {
    const [bannerloaded, setBannerLoaded] = useState(false);
    const [dancersloaded, setDancersLoaded] = useState(false);

    const imageDir = "./public/dancers/images";
    const jsonFile = "./public/dancers/dancers.json";
    const placeHolder = "Dancers coming soon!";

    

    return (
        <div
            style={{
                transition: "opacity 0.5s ease-in-out",
                opacity: bannerloaded ? 1 : 0,
            }}
        >
            <div className={`bg-gray-300 relative`}>
                <div className="absolute inset-0 flex flex-col justify-center pointer-events-none pt-[25%] md:pt-[20%] pl-[2%] md:pl-[5%] px-[10%]  lg:pl-[10%]">
                    <div className="text-white text-left pb-[30%]">
                        <div className="bg-gray-100 bg-opacity-0 rounded-xl inline-block">
                            <h1 className="text-4xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl  font-bold">
                                Our Dancers
                            </h1>
                        </div>
                    </div>
                </div>
                <img
                    src="/assets/banners/dancers/girl_smiling_no_text_black.webp"
                    width={1920}
                    height={1080}
                    alt="Dancers Cover"
                    loading="eager"
                    className={`object-cover midmd:w-full h-auto md:h-full ${
                        bannerloaded ? "loaded" : "loading"
                    }`}
                    onLoad={() => setBannerLoaded(true)}
                />
            </div>

            <div
                className={`bg-colour-light py-[5%] px-[5%] page-content ${
                    dancersloaded ? "loaded" : "loading"
                }`}
                style={{
                    transition: "opacity 0.5s ease-in-out",
                    opacity: dancersloaded ? 1 : 0,
                }}
            >
                <ImageGrid
                    photoDir={imageDir}
                    jsonFile={jsonFile}
                    placeHolder={placeHolder}
                    onLoad={() => setDancersLoaded(true)}
                />
            </div>
            <div className="bg-colour-dark text-black">
                <div className="margin-centered pt-[3%]">
                    <p className="pb-[3%] text-md md:text-xl font-semibold">
                        Our ensemble is comprised of talented dancers who not
                        only showcase their skills on stage but also embody
                        dedication and resilience in every aspect of their
                        lives. Each dancer joins our family through a selective
                        audition process, committing to a rigorous schedule of
                        rehearsals and performances that demand both time and
                        energy. This commitment is a testament to their
                        dedication to preserving Ukrainian cultural values
                        through the art of dance.
                        <br />
                        <br />
                        Despite the demands of the ensemble, many of our dancers
                        are also working professionals in various fields. They
                        masterfully balance their careers with their passion for
                        dance, demonstrating remarkable discipline and passion.
                        This unique blend of professional life and artistic
                        dedication enriches our group, bringing diverse
                        experiences and perspectives that enhance our
                        performances.
                    </p>
                    <div className="justify-center items-center flex pb-[5%]">
                        <GoldButton
                            href="/joinus"
                            text="Join Us"
                            className="lg:text-3xl"
                        />
                    </div>
                </div>
                <div className=" bg-colour-dark py-[1%] pt-[2%] text-black">
                    <div className="margin-centered midmd:columns-1 relative justify-self"></div>
                </div>
            </div>

            <Sash />
        </div>
    );
}
