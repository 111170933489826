import { useState, useEffect } from "react";
import { ImageGridCell } from "../../interfaces/ImageGridCell";

type ImageGridProps = {
    placeHolder?: string;
    photoDir: string;
    jsonFile: string;
    backgroundColour?: string;
    onLoad?: () => void;
};

export const ImageGrid = ({
    placeHolder,
    photoDir,
    jsonFile,
    backgroundColour = "",
    onLoad,
}: ImageGridProps) => {
    const [imageData, setImageData] = useState<ImageGridCell[]>([]);

    useEffect(() => {
        const fetchImageData = async () => {
            try {
                const response = await fetch("/api/getGridImages", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ photoDir, jsonFile }),
                });

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                const { data } = await response.json();
                setImageData(data);
                if (onLoad) {
                    onLoad();
                }
                console.log(imageData.length);
            } catch (error) {
                setImageData([]);
                if (onLoad) {
                    onLoad();
                }
                //console.error("Failed to fetch image data:", error);
            }
        };

        fetchImageData();
    }, [photoDir, jsonFile, onLoad]);

    return (
        <>
            {imageData.length > 0 ? (
                <div className="margin-centered midmd:columns-1 relative justify-self">
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        {imageData.map((cell, index) => (
                            <div key={index} className="relative">
                                <div className="w-full h-full">
                                    <img
                                        src={`data:image/jpeg;base64,${cell.image}`}
                                        alt={
                                            cell.name
                                                ? `Image of ${cell.name}`
                                                : `Image ${index + 1}`
                                        }
                                        width={500}
                                        height={500}
                                    />
                                    {cell.name ? (
                                        <h3 className="w-full text-black text-center items-center text-sm md:text-lg lg:text-2xl xl:text-3xl font-bold pt-[3%]">
                                            {cell.name}
                                        </h3>
                                    ) : (
                                        <></>
                                    )}
                                    {cell.title ? (
                                        <h3 className="w-full text-black text-center items-center text-sm md:text-sm lg:text-xl xl:text-1xl pt-[1%]">
                                            {cell.title}
                                        </h3>
                                    ) : (
                                        <></>
                                    )}
                                    {cell.info ? (
                                        <p className="w-full text-black text-center items-center text-sm md:text-sm lg:text-lg xl:text-xl pt-[2%]">
                                            {cell.info}
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : placeHolder ? (
                <p className="text-4xl">{placeHolder}</p>
            ) : (
                <p></p>
            )}
        </>
    );
};
