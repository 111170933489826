import React, { useState, FormEvent } from "react";
import ReCAPTCHA from "react-google-recaptcha";
//import { FormData } from "../../interfaces/FormData";

interface FormData {
    name: string;
    email: string;
}

type ContactFormProps = {
    onLoad?: () => void;
};

export default function MailForm({ onLoad }: ContactFormProps) {
    // State for each input with initial values as empty strings

    const [maploaded, setMapLoaded] = useState(false);

    const contactDisabled = false;

    const [formData, setFormData] = useState<FormData>({
        name: "",
        email: "",
    });
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const recaptchaRef = React.createRef<ReCAPTCHA>();

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCaptchaVerify = (response: string | null): void => {
        if (response) {
            setIsCaptchaVerified(true);
        }
    };

    // Function to handle form submission
    const handleSubmit = async (
        event: FormEvent<HTMLFormElement>
    ): Promise<void> => {
        event.preventDefault();
        /*
        if (!isCaptchaVerified) {
            alert("Please complete the CAPTCHA.");
            return;
        }
        */
        if (contactDisabled) {
            alert(
                "Nice try, our email is yevshan1960@gmail.com contact us here since you're so eager :)"
            );
            return;
        }

        const { name, email } = formData;

        if (!name || !email) {
            alert("Please fill in all required fields.");
            return;
        }

        const formDataToSend = new URLSearchParams();
        formDataToSend.append("entry.2005620554", name); // Replace XXXXXXX with the field ID for each field in your Google Form
        formDataToSend.append("entry.1166974658", email);

        try {
            await fetch(
                "https://docs.google.com/forms/d/e/1FAIpQLSdWXYB3oGqQ_3q01EqSRqDsgNh-l06WyWDnpirraEMkFMPr6Q/formResponse?" +
                    new URLSearchParams({
                        "entry.2005620554": name,
                        emailAddress: email,
                    }),
                {
                    mode: "no-cors",
                }
            );
            alert("Form submitted successfully!");
            setFormData({
                name: "",
                email: "",
            });
        } catch (error) {
            console.error("Error submitting form:", error);
            alert(
                "An error occurred while submitting the form. Please try again later."
            );
        }
    };

    return (
        <div className="bg-colour-light margin-centered text-white p-8">
            <h2 className="text-black text-3xl font-bold mb-4">
                Join our Mailing List!
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-black">
                <div></div>
                <div>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <input
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Name"
                            className="w-full p-2 bg-colour-dark"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />

                        <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            className="w-full p-2 bg-colour-dark"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <button
                            type="submit"
                            className="w-full bg-yevshan-gold p-2"
                            disabled={contactDisabled}
                        >
                            {contactDisabled ? "Coming Soon" : "SEND"}
                        </button>
                        <div className="flex justify-center">
                            {/*<ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LfxQuwpAAAAAFSUoaG51myPkAU6c1jLOR6X5E7t -> site key"
                                onChange={handleCaptchaVerify}
    />*/}
                        </div>
                    </form>
                </div>
            </div>
            <div></div>
        </div>
    );
}
