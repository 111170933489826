"use client";

import React, { useEffect, useRef, useState } from "react";
import "../../App.css";

type TimelineEvent = {
    year: string;
    content: string;
    section?: string;
};

type TimelineProps = {
    onLoad?: () => void;
};

const Timeline = ({ onLoad }: TimelineProps) => {
    const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

    // Use state to track and set heights
    const [heights, setHeights] = useState<number[]>([]);

    const [timelineEvents, setTimeLineEvents] = useState<TimelineEvent[]>([]);

    // Measure the heights of each content container after they are rendered
    useEffect(() => {
        const newHeights = itemRefs.current.map(
            (ref) => ref?.offsetHeight ?? 0
        );
        setHeights(newHeights);

        if (onLoad) {
            onLoad();
        }
    }, [timelineEvents, onLoad]);

    useEffect(() => {
        const fetchImageData = async () => {
            try {
                const response = await fetch("/api/getHistoryInfo");

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                //console.log(response);
                const { data } = await response.json();
                console.log(data);

                setTimeLineEvents(data.timeline);
                console.log(data);
            } catch (error) {
                console.error("Failed to fetch image data:", error);
            }
        };

        fetchImageData();
    }, []);

    const formatDate = (dateString: string) => {
        const hasDay = dateString.length === 10;
        const hasMonth = dateString.length >= 7;

        // Extract year, month, and day from the input date string
        const [year, month, day] = dateString.split("-").map(Number);

        // Create a new Date object with the extracted values
        // Note: month is 0-indexed in JavaScript Date object
        const date = new Date(year, (month || 1) - 1, day || 1);

        // Define the formatting options
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            ...(hasMonth && { month: "long" }),
            ...(hasDay && { day: "numeric" }),
        };

        // Format the date using Intl.DateTimeFormat
        return new Intl.DateTimeFormat("en-US", options).format(date);
    };

    // Helper function to parse the date string for sorting
    const parseDate = (dateString: string) => {
        const [year, month = "01", day = "01"] = dateString.split("-");
        return new Date(`${year}-${month}-${day}T00:00:00Z`);
    };

    // Group events by section and maintain the order of sections
    const sectionOrder: string[] = [];
    const groupedEvents: { [key: string]: TimelineEvent[] } = {};

    timelineEvents.forEach((event) => {
        const section = event.section || "default";
        if (!groupedEvents[section]) {
            groupedEvents[section] = [];
            sectionOrder.push(section); // Maintain the order of sections
        }
        groupedEvents[section].push(event);
    });

    // Sort events within each section by date
    sectionOrder.forEach((section) => {
        groupedEvents[section].sort(
            (a, b) => parseDate(a.year).getTime() - parseDate(b.year).getTime()
        );
    });

    return (
        <div className="relative flex flex-col items-center my-8">
            {/* Central Line */}
            <div className="absolute left-1/2 transform -translate-x-1/2 w-[2px] bg-gray-300 h-full"></div>

            {sectionOrder.map((section, sectionIndex) => (
                <React.Fragment key={section}>
                    {/* Section header */}
                    {section !== "default" && (
                        <div className="z-10 w-full text-center my-10">
                            <h2 className="text-2xl font-bold">{section}</h2>
                        </div>
                    )}

                    {groupedEvents[section].map((event, index) => (
                        <div
                            key={index}
                            className="w-full flex justify-center items-center my-4 relative"
                            style={{
                                height: `${heights[sectionIndex + index]}px`,
                            }}
                        >
                            {/* Event content */}
                            <div
                                className={`absolute ${
                                    (sectionIndex + index) % 2 === 0
                                        ? "right-1/2 mr-10"
                                        : "left-1/2 ml-10"
                                }`}
                                ref={(el) => {
                                    itemRefs.current[sectionIndex + index] = el;
                                }} // Set the ref here
                            >
                                <div className="max-w-md px-4 py-2 text-sm font-medium bg-white shadow-lg rounded-lg">
                                    <h3 className="text-lg font-semibold">
                                        {formatDate(event.year)}
                                    </h3>
                                    <p className="text-gray-600">
                                        {event.content}
                                    </p>
                                </div>
                            </div>
                            {/* Dot on the line */}
                            <div className="z-10 w-4 h-4 bg-yevshan-gold rounded-full"></div>
                        </div>
                    ))}
                </React.Fragment>
            ))}
        </div>
    );
};

export default Timeline;
