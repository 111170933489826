import '../../App.css';import { Link } from "react-router-dom";
import { MenuItem } from "../../interfaces/MenuItems";
import { useState, useRef, useCallback, useEffect } from "react";
import { HiChevronRight } from "react-icons/hi";

import { useMediaQuery } from "../../hooks/useScreenSize";

interface Props {
    item: MenuItem;
    toggleNavbar: () => void;
}

export default function Dropdown(props: Props) {
    const { item, toggleNavbar } = props;
    const [isOpen, setIsOpen] = useState(false);
    const menuItems = item?.children ? item.children : [];
    const timeoutRef = useRef<NodeJS.Timeout>();

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleMouseEnter = () => {
        clearTimeout(timeoutRef.current);
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setIsOpen(false);
        }, 50); // Adjust the delay time as needed (in milliseconds)
    };

    const isBreakPoint = useMediaQuery(820);

    return isBreakPoint ? (
        <div className="relative">
            <button
                className={`hover:text-yevshan-gold ${
                    /* idk why this ml-2 looks bad on browser phone but good on my iphone*/ 0
                } ml-2 font-semibold md:text-xl flex items-center lg:text-xl whitespace-nowrap cursor-default`}
                onClick={toggle}
            >
                {item.title}
                <span
                    className={`transform transition-transform ${
                        isOpen ? "rotate-90" : ""
                    }`}
                >
                    <HiChevronRight />
                </span>
            </button>

            <div
                className={`midmd:absolute midmd:top-8 midmd:z-30 flex items-start flex-col midmd:py-4 midmd:bg-zinc-100 midmd:rounded-md midmd:border midmd:border-yevshan-gold ${
                    isOpen ? "flex" : "hidden"
                }`}
            >
                {menuItems.map((item) => (
                    <Link
                        key={item.route || ""}
                        className="hover:text-yevshan-gold px-4 py-1 whitespace-nowrap"
                        to={item?.route || ""}
                        onClick={() => {
                            toggleNavbar();
                            toggle();
                        }}
                    >
                        {item.title}
                    </Link>
                ))}
            </div>
        </div>
    ) : (
        <div
            className="relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <p className=" hover:text-yevshan-gold font-semibold md:text-xl flex items-center lg:text-xl whitespace-nowrap cursor-default">
                {item.title}
            </p>
            <div
                className={`midmd:absolute midmd:top-8 midmd:z-30 flex flex-col midmd:py-4 midmd:bg-zinc-100 midmd:rounded-md midmd:border midmd:border-yevshan-gold ${
                    isOpen ? "flex" : "hidden"
                }`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {menuItems.map((item) => (
                    <Link
                        key={item.route}
                        className="hover:text-yevshan-gold px-4 py-1 whitespace-nowrap"
                        to={item?.route || ""}
                    >
                        {item.title}
                    </Link>
                ))}
            </div>
        </div>
    );
}
