import { GoldButton } from "./utils/GoldButton";
import { Sash } from "./utils/Sash";
import { Event } from "../interfaces/Event";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EventCarousel } from "./utils/EventCarousel";

export default function Page() {
    const navigate = useNavigate();
    const [upcoming, setJSONData] = useState<Event[]>([]);

    const [bannerloaded, setBannerLoaded] = useState(false);
    const [eventsloaded, setEventsLoaded] = useState(false);

    useEffect(() => {
        const fetchImageData = async () => {
            try {
                const response = await fetch("/api/getEvents");
                if (response.ok) {
                    const responseData = await response.json();
                    const data: Event[] = responseData.data;
                    const filteredData = data.filter(
                        (event: Event) => new Date(event.date) >= new Date()
                    );
                    const sortedData = filteredData.sort(
                        (a: Event, b: Event) =>
                            new Date(a.date).getTime() -
                            new Date(b.date).getTime()
                    );
                    setJSONData(sortedData);
                    setEventsLoaded(true);
                } else {
                    throw new Error(
                        `Failed to fetch images: ${response.statusText}`
                    );
                }
            } catch (error) {
                console.error("Error fetching images:", error);
                throw error; // Rethrow the error to be caught by the component
            }
        };
        fetchImageData();
    }, [eventsloaded]);

    const formatDate = (dateString: string) => {
        // Extract year, month, and day from the input date string
        const [year, month, day] = dateString.split("-").map(Number);

        // Create a new Date object with the extracted values
        // Note: month is 0-indexed in JavaScript Date object
        const date = new Date(year, month - 1, day);

        // Define the formatting options
        const options: Intl.DateTimeFormatOptions = {
            month: "long",
            day: "numeric",
            year: "numeric",
        };

        // Format the date using Intl.DateTimeFormat
        return new Intl.DateTimeFormat("en-US", options).format(date);
    };

    const handleContactClick = async () => {
        // Navigate to "events/upcoming" first
        await navigate(`/contact`);
        window.scroll(0, 0);

        // Wait for a short duration before navigating to the specific event section
        setTimeout(() => {
            const eventSection = document.getElementById("contact-form");
            if (eventSection) {
                eventSection.scrollIntoView({ behavior: "smooth" });
            }
        }, 500);
    };

    return (
        <div
            style={{
                transition: "opacity 0.5s ease-in-out",
                opacity: bannerloaded ? 1 : 0,
            }}
        >
            <div className="relative">
                <div className="absolute inset-0 flex flex-col justify-center pointer-events-none pt-[25%] md:pt-[20%] pl-[4%] md:pl-[5%] px-[10%]  lg:pl-[10%]">
                    <div className="text-white  text-left pb-[30%]">
                        <div className="bg-gray-100 bg-opacity-0 rounded-xl inline-block">
                            <h1 className="text-4xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl font-bold">
                                Upcoming <br /> Events
                            </h1>
                        </div>
                    </div>
                </div>
                <img
                    src="/assets/banners/upcoming/rush_black.webp"
                    width={1920}
                    height={1080}
                    alt="Dancers Cover"
                    loading="eager"
                    className="object-cover midmd:w-full h-auto md:h-full"
                    onLoad={() => setBannerLoaded(true)}
                />
            </div>
            <div className="bg-colour-dark text-black">
                <div className="margin-centered pt-[3%]">
                    <p className="pb-[3%] text-md md:text-xl font-semibold">
                        Want to know where you can see us next? Check out our
                        upcoming events listed below and join us for an
                        unforgettable performance!
                    </p>
                </div>
                <div
                    style={{
                        transition: "opacity 0.5s ease-in-out",
                        opacity: eventsloaded ? 1 : 0,
                    }}
                >
                    {upcoming.length > 0 ? (
                        upcoming.map((event: Event, index: number) => (
                            <div
                                key={index}
                                className={`relative pt-[2%] ${
                                    index % 2 === 0
                                        ? "bg-colour-light"
                                        : "bg-colour-dark"
                                }`}
                                id={event.name.replaceAll(" ", "")}
                            >
                                <div className="w-full h-full margin-centered text-center pb-[4%]">
                                    <h1 className="text-black text-3xl font-bold pb-[1%]">
                                        {event.name}
                                    </h1>
                                    <h2 className="text-black text-1xl font-bold pb-[1%]">
                                        {formatDate(event.date)}
                                    </h2>
                                    <h3 className="text-black text-lg pb-[1%]">
                                        {event.location}
                                    </h3>
                                    <div
                                        style={{
                                            maxWidth: "700px",
                                            margin: "0 auto",
                                        }}
                                        className="pb-[5%]"
                                    >
                                        {event.image.length > 1 ? (
                                            <EventCarousel
                                                images64={event.image}
                                            />
                                        ) : (
                                            <img
                                                src={`data:image/jpeg;base64,${event.image}`}
                                                style={{
                                                    width: "100%",
                                                    height: "auto",
                                                }}
                                                alt={`Image ${index + 1}`}
                                                width={500}
                                                height={281}
                                            />
                                        )}
                                    </div>
                                    {event.tickets == "" ? (
                                        <span
                                            className={`px-4 py-3 font-semibold shadow-md no-underline rounded-full bg-yevshan-gold hover:cursor-pointer text-black md:text-lg lg:text-xl border-blue btn-primary  hover:bg-[#b8b795] focus:outline-none active:shadow-none mr-2 whitespace-nowrap`}
                                            onClick={() => handleContactClick()}
                                        >
                                            {`Contact Us for More Information`}
                                        </span>
                                    ) : (
                                        <GoldButton
                                            href={event.tickets}
                                            text={"Tickets"}
                                        />
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="margin-centered text-center pt-[3%]">
                            <p className="pb-[3%] text-md md:text-xl font-semibold">
                                No Upcoming Events!
                                <br />
                                Check back soon for updates!
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <Sash />
        </div>
    );
}
