"use client";

import "../../App.css";

import { useMediaQuery } from "../hooks/useScreenSize";
import { useEffect, useState } from "react";

type BackgroundVideoProps = {
    onLoad?: () => void;
};

const BackgroundVideo = ({ onLoad }: BackgroundVideoProps) => {
    const [videoloaded, setVideoLoaded] = useState(false);

    const isBreakPoint = useMediaQuery(820);

    const isLandscape = () => {
        return window.matchMedia("(orientation: landscape)").matches;
    };

    useEffect(() => {
        const video = document.getElementById("bgvideo");
        if (video) {
            video.addEventListener("loadeddata", handleVideoLoaded);
            return () => {
                video.removeEventListener("loadeddata", handleVideoLoaded);
            };
        }
    }, []); // Empty dependency array ensures this effect runs only once after the initial render

    const handleVideoLoaded = () => {
        if (onLoad) onLoad();
        setVideoLoaded(true);
    };

    return (
        <div
            className={`font-greatvibes relative flex justify-center items-center h-screen`}
        >
            <video
                id="bgvideo"
                autoPlay
                muted
                loop
                playsInline
                preload="metadata"
                className="absolute inset-0 w-full h-full object-cover"
                style={{ objectPosition: "center" }}
                onLoad={() => {
                    if (onLoad) onLoad();
                }}
            >
                <source
                    src="/assets/videos/orange_vid_new.mp4"
                    type="video/mp4"
                />
            </video>
            <div className="absolute inset-0 flex flex-col justify-center items-center pointer-events-none">
                <div
                    className={`text-white text-center pb-[100%] sm:pb-[50%] md:pb-[40%] lg:pb-[15%] `}
                >
                    {isBreakPoint ? (
                        <>
                            <h1
                                className={`text-5xl font-great_vibes md:text-6xl lg:text-7xl xl:text-9xl font-bold pt-[50%] mt-[10%]`}
                            >
                                We Dance
                            </h1>
                            <h1 className="text-5xl font-great_vibes md:text-6xl lg:text-7xl xl:text-9xl font-bold pt-[30%]">
                                Because
                            </h1>
                            <h1 className="text-5xl font-great_vibes md:text-6xl lg:text-7xl xl:text-9xl font-bold pt-[30%] ">
                                We Love It
                            </h1>
                        </>
                    ) : (
                        <>
                            <h1
                                className={`text-5xl font-great_vibes md:text-6xl lg:text-7xl xl:text-9xl font-bold pt-[50%] md:pt-0 md:mb-[20%] md:mt-[20%]`}
                            >
                                We Dance
                            </h1>
                            <h1 className="text-5xl font-great_vibes md:text-6xl lg:text-7xl xl:text-9xl font-bold pt-[50%] md:pt-0 mt-6">
                                Because We Love It
                            </h1>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
export default BackgroundVideo;
