import "../../App.css";
import { Link } from "react-router-dom";

type GoldButtonProps = {
    href: string;
    text: string;
    onClick?: () => void
    className?: string;
};

export const GoldButton = ({ href, text, className = "", onClick}: GoldButtonProps) => {
    return (
        <Link
            to={href}
            className={`${className} px-4 py-3 font-semibold shadow-md no-underline rounded-full bg-yevshan-gold text-black md:text-lg lg:text-xl border-blue btn-primary  hover:bg-[#b8b795] focus:outline-none active:shadow-none mr-2 whitespace-nowrap`}
            onClick={() => {
                window.scroll(0, 0);
                if (onClick) onClick();
            }}
        >
            {text}
        </Link>
    );
};
