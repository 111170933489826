import { Sash } from "./utils/Sash";
import Link from "react-router-dom";

import { ImageGrid } from "./utils/ImageGrid";
import { ImageGridCell } from "../interfaces/ImageGridCell";
import { useState } from "react";

interface BoardMember extends ImageGridCell {
    name: string;
    image: string;
    title: string;
}

export default function Page() {
    const [bannerloaded, setBannerLoaded] = useState(false);

    const photoDir = "./public/board-of-directors/images";
    const jsonFile = "./public/board-of-directors/board_of_directors.json";
    const placeHolder = "Board of Directors Coming Soon!";

    return (
        <div
            style={{
                transition: "opacity 0.5s ease-in-out",
                opacity: bannerloaded ? 1 : 0,
            }}
        >
            <div className=" bg-colour-dark">
                <div className="relative">
                    <div className=" absolute inset-0 flex flex-col justify-center pointer-events-none pt-[25%] md:pt-[20%] pl-[2%] pr-[2%] md:pl-[5%] sm:px-[10%]  lg:pr-[3%]">
                        <div className="text-white text-right pb-[30%]">
                            <h1 className="text-3xl sm:text-4xl midmd:text-5xl lg:text-7xl xl:text-8xl 2xl:text-9xl  font-bold">
                                Board of Directors
                            </h1>
                        </div>
                    </div>
                    <img
                        src="/assets/banners/board-of-directors/girls_dancing.webp"
                        width={1920}
                        height={1080}
                        alt="Dancers Cover"
                        loading="eager"
                        className="object-cover midmd:w-full h-auto md:h-full"
                        onLoad={() => setBannerLoaded(true)}
                    />
                </div>
            </div>

            <div className="bg-colour-light py-[1%] text-black">
                <div className="midmd:grid midmd:grid-cols-2 midmd:max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8 justify-self">
                    <div className="relative w-full flex flex-col items-center justify-start md:order-1">
                        <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold md:pb-[5%] text-center">
                            Presidents Message
                        </h1>

                        <img
                            src={
                                "/assets/board-of-directors/president/brenda.jpg"
                            }
                            width={480}
                            height={270}
                            alt="Yevshan"
                            className="max-w-[400px] h-auto pb-[5%]"
                        />
                        <h2 className="text-1xl md:text-2xl lg:text-3xl xl:text-4xl font-bold pb-[5%] text-center">
                            Brenda Kalyn
                        </h2>
                    </div>
                    <div className="flex items-center md:order-2">
                        <p>
                            Message Coming Soon.
                        </p>
                    </div>
                </div>
            </div>

            <div className="bg-colour-dark py-[5%] px-[5%]">
                <ImageGrid
                    photoDir={photoDir}
                    jsonFile={jsonFile}
                    placeHolder={placeHolder}
                />
            </div>

            <Sash />
        </div>
    );
}
